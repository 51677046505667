// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./ye6bV7034-0.js";

const cycleOrder = ["BTZVK6omb", "mbwrGrRg_"];

const serializationHash = "framer-ggw9E"

const variantClassNames = {BTZVK6omb: "framer-v-r5hjdc", mbwrGrRg_: "framer-v-1m4yklm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {QqP3jQNpQ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Close: "mbwrGrRg_", Menu: "BTZVK6omb"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BTZVK6omb", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BTZVK6omb", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16y2uw0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-r5hjdc", className, classNames)} data-framer-name={"Menu"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"BTZVK6omb"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16y2uw0} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({mbwrGrRg_: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1rdolay"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"TtCaeSCPs"} style={{backgroundColor: "var(--token-c5fac6e7-b7e1-4b65-b9b5-b2dad18a6cd6, rgb(41, 41, 41))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{mbwrGrRg_: {rotate: -45}}}/><motion.div className={"framer-zen1f1"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"L3VwsmWs3"} style={{backgroundColor: "var(--token-c5fac6e7-b7e1-4b65-b9b5-b2dad18a6cd6, rgb(41, 41, 41))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{mbwrGrRg_: {rotate: 45}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-ggw9E[data-border=\"true\"]::after, .framer-ggw9E [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ggw9E.framer-v3qtr6, .framer-ggw9E .framer-v3qtr6 { display: block; }", ".framer-ggw9E.framer-r5hjdc { cursor: pointer; height: 44px; overflow: hidden; position: relative; width: 44px; }", ".framer-ggw9E .framer-1rdolay { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(62.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-ggw9E .framer-zen1f1 { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(37.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-ggw9E.framer-v-1m4yklm.framer-r5hjdc { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 44px); }", ".framer-ggw9E.framer-v-1m4yklm .framer-1rdolay, .framer-ggw9E.framer-v-1m4yklm .framer-zen1f1 { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mbwrGrRg_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerye6bV7034: React.ComponentType<Props> = withCSS(Component, css, "framer-ggw9E") as typeof Component;
export default Framerye6bV7034;

Framerye6bV7034.displayName = "Icon";

Framerye6bV7034.defaultProps = {height: 44, width: 44};

addPropertyControls(Framerye6bV7034, {variant: {options: ["BTZVK6omb", "mbwrGrRg_"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerye6bV7034, [])